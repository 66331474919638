import React, {useState} from 'react';

import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';

import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField, Typography,
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';

import {KeyboardBackspace as KeyboardBackspaceIcon} from '@material-ui/icons';
import roles from 'roles';
import useUsers from 'api/useUsers';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4)
    },
    formControl: {
        minWidth: 120,
        width: '100%',
    },
    button: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    label: {
        display: 'flex',
        justifySelf: 'flex-start',
        float: "left",
        justifyContent: 'flex-start',
        flexShrink: 0,

    }
}));

function CreateBrandForm(props) {

    const {agencies} = props;
				 

    const [brandName, setBrandName] = useState('');
    const [pharmaCompany, setPharmaCompany] = useState('');
    const [adAgency, setAdAgency] = useState('');
    const [searchStrategist, setSearchStrategist] = useState([]);
    const [brandDomainUrls, setBrandDomainUrls] = useState([]);
    const [newUrl, setNewUrl] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [lastDomainChipCreatedBySpace, setLastDoimainChipCreatedBySpace] = useState(false);
    const [lastEmailChipCreatedBySpace, setLastEmailChipCreatedBySpace] = useState(false);
    const [emailAlerts, setEmailAlerts] = useState([]);
    const [error, setError] = useState(false);
    const history = useHistory();
    const [bingEnabled, setBingEnabled] = useState(false);
															 
																		
	const [weeklyReportEnabled, setWeeklyReportEnabled] = useState(true);


    let params = new URLSearchParams();
    params.append('agencyId', adAgency.agencyId);
    params.append('role', roles.SEARCH_STRATEGIST.name);
    const {users: strategists} = useUsers(params, [adAgency], adAgency !== '');

    const handleSearchStrategistChange = (event, newValue, reason) => {
        if (reason === 'clear') {
            setSearchStrategist([])
        } else if (reason === 'select-option') {
            setSearchStrategist([
                ...searchStrategist,
                ...newValue.filter((option) => searchStrategist.indexOf(option) === -1),
            ]);
        } else if (reason === 'remove-option') {
            setSearchStrategist(searchStrategist.filter(
                option => searchStrategist.indexOf(option) !== searchStrategist.length - 1
                )
            )
			 
        }
    };

    const handleSearchStrategistDelete = (event, item) => {
        setSearchStrategist(
            searchStrategist.filter(
                option => option.id !== item.id
            )
        );
    };

    const handleBrandDomainUrlChange = (event, newValue, reason) => {
        setBrandDomainUrls(newValue.filter(option => option.trim() !== ''));
        setLastDoimainChipCreatedBySpace(false);
    };


    const handleBrandDomainUrlOnKeyDown = (event) => {
        if (event.key === ' ' || event.key === 'Tab' && newUrl !== '' && newUrl !== ' ') {
            let urls = brandDomainUrls;
            if (!urls.includes(newUrl)) {
                urls.push(newUrl);
            }
            handleBrandDomainUrlChange(event, urls, '');
            setNewUrl('');
            setLastDoimainChipCreatedBySpace(true);
        } else if (event.key === 'Backspace' && lastDomainChipCreatedBySpace && newUrl === '') {
            let urls = brandDomainUrls;
            urls.pop();
            handleBrandDomainUrlChange(event, urls, '');
        }
    };

    const handleBrandDomainUrlBlur = (event) => {
        if (newUrl !== '' && newUrl !== ' ') {
            let urls = brandDomainUrls;
            if (!urls.includes(newUrl)) {
                urls.push(newUrl);
            }
            handleBrandDomainUrlChange(event, urls, '');
            setNewUrl('');
        }
    };

    const handleBrandDomainUrlDelete = (event, item) => {
        setBrandDomainUrls(
            brandDomainUrls.filter(
                option => option !== item
            )
        );
    };

    const handleEmailAlertsChange = (event, newValue, reason) => {
        setEmailAlerts(newValue.filter(option => option.trim() !== ''));
        setLastEmailChipCreatedBySpace(false);
    };

    const handleEmailAlertsOnKeyDown = (event) => {
        if (event.key === ' ' || event.key === 'Tab' && newEmail !== '' && newEmail !== ' ') {
            let emails = emailAlerts;
            if (!emails.includes(newEmail)) {
                emails.push(newEmail);
            }
            handleEmailAlertsChange(event, emails, '');
            setNewEmail('');
            setLastEmailChipCreatedBySpace(true);
        } else if (event.key === 'Backspace' && lastEmailChipCreatedBySpace && newEmail === '') {
            let emails = emailAlerts;
            emails.pop();
            handleEmailAlertsChange(event, emails, '');
        }
    };
    const handleEmailAlertsOnBlur = (event) => {
        if (newEmail !== '' && newEmail !== ' ') {
            let emails = emailAlerts;
            if (!emails.includes(newEmail)) {
                emails.push(newEmail);
            }
            handleEmailAlertsChange(event, emails, '');
            setNewEmail('');
        }
    };

    const handleEmailAlertsDelete = (event, item) => {
        setEmailAlerts(
            emailAlerts.filter(
                option => option !== item
            )
        );
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        props.saveBrand({
            brandName,
            pharmaCompany,
            agencyId: adAgency.agencyId,
            searchStrategist: searchStrategist.map(value => value.id),
            brandDomainUrls,
            emailAlerts,
            bingEnabled,				   
			weeklyReportEnabled			  
        });
    };

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={10} lg={8} xl={6}>
                    <Card>
                        <CardHeader
                            avatar={
                                <IconButton aria-label='Go Back' edge='start' onClick={() => history.goBack()}>
                                    <KeyboardBackspaceIcon/>
                                </IconButton>
                            }
                            title='Add Brand'
                            titleTypographyProps={
                                {variant: 'h5'}
                            }
                            subheaderTypographyProps={
                                {variant: 'body1'}
                            }
                            subheader='Complete the following fields and select create to add a new brand for you agency!'
                        />
                        <Divider/>
                        <CardContent>
                            <form onSubmit={handleSubmit}>
                                {/* Brand Name */}
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} required error={error}>
                                            <TextField
                                                id='brand-name'
                                                label='Brand Name'
                                                value={brandName}
                                                onChange={event => setBrandName(event.target.value)}
                                                size='small'
                                                required
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {/* Pharma Company */}
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} required error={error}>
                                            <TextField
                                                id='pharma-company'
                                                label='Pharma Company'
                                                value={pharmaCompany}
                                                onChange={event => setPharmaCompany(event.target.value)}
                                                required
                                                size='small'
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {/* Ad Agency */}
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} required error={error}>
                                            <InputLabel id='ad-agency-label'>Ad Agency</InputLabel>
                                            <Select
                                                labelId='ad-agency-label'
                                                id='ad-agency'
                                                value={adAgency}
                                                onChange={event => setAdAgency(event.target.value)}
                                                input={<Input/>}
						   
                                            >
                                                {agencies.map((agency) => (
                                                    <MenuItem key={agency.agencyId} value={agency}>
                                                        {agency.agencyName}
                                                    </MenuItem>
                                                ))}

				  
			   
				 
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {/* Search Strategist */}
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} required error={error}>
                                            <Autocomplete
                                                multiple
                                                id='search-strategist'
                                                options={strategists}
                                                getOptionLabel={(option) => option.name}
                                                autoHighlight
                                                getOptionSelected={
                                                    (option, searchStrategist) => {
                                                        return option.id === searchStrategist.id
                                                    }
                                                }
                                                filterSelectedOptions
                                                value={searchStrategist}
                                                onChange={
                                                    (event, newValue, reason) =>
                                                        handleSearchStrategistChange(event, newValue, reason)
                                                }
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                            <Chip
                                                                {...getTagProps({index})}
                                                                key={option.id}
                                                                label={option.name}
                                                                size='small'
                                                                onDelete={
                                                                    (event) =>
                                                                        handleSearchStrategistDelete(event, option)
                                                                }
                                                            />
                                                        )
                                                    )}
                                                renderInput={params => (
                                                    <TextField {...params} label='Search Strategist *'/>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {/* Domain Urls */}
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} error={error}>
                                            <Autocomplete
                                                multiple
                                                freeSolo
                                                id='brand-domain-urls'
                                                options={[]}
                                                onKeyDown={handleBrandDomainUrlOnKeyDown}
                                                value={brandDomainUrls}
                                                onChange={
                                                    (event, newValue, reason) =>
                                                        handleBrandDomainUrlChange(event, newValue, reason)
                                                }
                                                onInputChange={(event, value, reason) => {
                                                    setNewUrl(value.trim());
                                                }}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                            <Chip
                                                                {...getTagProps({index})}
                                                                label={option}
                                                                size='small'
                                                                onDelete={
                                                                    (event) =>
                                                                        handleBrandDomainUrlDelete(event, option)
                                                                }
                                                            />
                                                        )
                                                    )}
                                                renderInput={params => (
                                                    <>
                                                        <TextField
                                                            {...params}
                                                            inputProps={{...params.inputProps, value: newUrl}}
                                                            label='Brand Website Domain URLs'
                                                            onBlur={handleBrandDomainUrlBlur}
                                                            error={brandDomainUrls.length > 5}
                                                        />
                                                        {brandDomainUrls.length > 5 &&
                                                        <FormHelperText error={brandDomainUrls.length > 5}>
                                                            This field is limited to 5 URLs
                                                        </FormHelperText>
                                                        }

                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {/* Email alerts */}
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} error={error}>
                                            <Autocomplete
                                                multiple
                                                freeSolo
                                                id='email-alerts'
                                                options={[]}
                                                onKeyDown={handleEmailAlertsOnKeyDown}
                                                value={emailAlerts}
                                                onChange={
                                                    (event, newValue, reason) =>
                                                        handleEmailAlertsChange(event, newValue, reason)
                                                }
                                                onInputChange={(event, value, reason) => {
                                                    setNewEmail(value.trim());
                                                }}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                            <Chip
                                                                {...getTagProps({index})}
                                                                label={option}
                                                                size='small'
                                                                onDelete={
                                                                    (event) =>
                                                                        handleEmailAlertsDelete(event, option)
                                                                }
                                                            />
                                                        )
                                                    )}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{...params.inputProps, value: newEmail}}
                                                        onBlur={handleEmailAlertsOnBlur}
                                                        label='Email Alerts'/>

                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {/* Bing Switch */}
                                <Grid container justifyContent="flex-start" spacing={3}>
													   
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} error={error}>
                                            <Typography variant='body1'>
                                                Enable Bing
                                            </Typography>
                                            <Grid component="label" container alignItems="center">
                                                <Grid item>Off</Grid>
                                                <Grid item>
                                                    <Switch
                                                        id="bingEnabled"
                                                        checked={bingEnabled}
                                                        onChange={event => setBingEnabled(event.target.checked)}
                                                        name="bingEnabled"
                                                    />
                                                </Grid>
                                                <Grid item>On</Grid>
                                            </Grid>
                                        </FormControl>
                                    </Grid>
														 
													  
																								   
																		
															 
														 
																								  
																	 
														   
														   
																		  
																			   
																												  
																			
													  
													   
																	
												   
													  
										   
															  
													  
																								   
																		
																  
														 
																								  
																	 
														   
														   
																			   
																					
																													   
																				 
													  
													   
																	
												   
													  
										   

						            {/* WeeklyReport Switch */}
                                    <Grid item xs={4}>
                                        <FormControl className={classes.formControl} error={error}>
                                            <Typography variant='body1'>
                                                Enable WeeklyReport
                                            </Typography>
                                            <Grid component="label" container alignItems="center">
                                                <Grid item>Off</Grid>
                                                <Grid item>
                                                    <Switch
                                                        id="weeklyReportEnabled"
                                                        checked={weeklyReportEnabled}
                                                        onChange={event => setWeeklyReportEnabled(event.target.checked)}
                                                        name="weeklyReportEnabled"
                                                    />
                                                </Grid>
                                                <Grid item>On</Grid>
                                            </Grid>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {/* Buttons */}
                                <Grid container spacing={3}>
                                    <Grid container item justify="flex-end" xs={12}>
                                        <Button
                                            variant="text"
                                            className={classes.button}
                                            onClick={() => history.goBack()}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            type='submit'
                                            className={classes.button}
                                            disabled={
                                                !brandName ||
                                                !pharmaCompany ||
                                                !adAgency ||
                                                !searchStrategist.length
                                            }
                                        >
                                            Create
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

CreateBrandForm.propTypes = {};

export default CreateBrandForm;
